.socialmedia-section {
  max-width: 1600px;
  margin: 0 auto;
}

.socialmedia-section h1 {
  margin: 0 2rem;
  padding: 2rem;
  font-weight: 700;
  color: #2D7689;
  text-align: center;
  border-bottom: 1px solid #d4d3d3;
}

.social-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin: 1rem;
}

#instagram-icon {
  height: 2rem;
  width: 2rem;
  background-color: #E12F6C;
  color: #fff;
  padding: 0.25rem;
  border-radius: 50%;
}

#github-icon {
  color: #2B2E32;
}

#linkedin-icon {
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid #fff;
  border-radius: 50%;
}

@media screen and (min-width: 768px) { 
  .social-icon {
    margin: 2rem;
  }
}