.mobile-contact-section,
.desktop-contact-section {
  display: block;
  background-color: #f9f7f7;
  max-width: 1600px;
  margin: 0 auto 1.5rem auto;
}

.desktop-contact-section {
  display: none;
}

.contact-navbar {
  width: 100%;
  background-color: #163F4D;;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #fff;
}

.contact-section-heading {
  margin: 0 2rem;
  padding: 2rem;
  font-weight: 700;
  color: #2D7689;
  text-align: center;
  border-bottom: 1px solid #d4d3d3;
}

.contact-article {
  color: #2D7689;
}

.contact-form {
  display: flex;
  flex-direction: column;
  padding: 0 3rem 3rem;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #edecec;
}

.contact-form textarea {
  height: 10rem;
}
.contact-form input:focus {
  outline: 1.5px solid #075624;
}

.form-btn {
  background-color: #163F4D;
  border: 1px solid #2D7689;
  border-radius: 0.4rem;
  color: #fff;
  margin-top: 2rem;
  padding: 0.75rem 2rem;
}
  
.form-btn:hover {
  background-color: #205d71;
  cursor: pointer;
}

.contact-article div {
  padding: 3rem;
}

@media screen and (min-width: 768px) {
  .contact-navbar {
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 2rem 2rem 4rem;
  }
  
  .contact-navbar div {
    flex-direction: row;
  }
}

@media screen and (min-width: 1248px) {
  .mobile-contact-section {
    display: none;
  }

  .desktop-contact-section {
    display: block;
    margin: 0 auto 3rem auto;
  }

  .contact-article {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .contact-form {
    padding: 3rem;
  }
}