@import url('https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap');


.intro-section {
  height: 700px;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(23,58,69,1) 35%, rgba(44,115,134,1) 100%);
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  color: #eeeded;
  animation: fadein 2s ease-in-out;
}

.intro-text, .skills {
  font-family: 'Saira Stencil One', cursive;
  letter-spacing: 0.1rem;
  text-shadow: -0.5px 0 #8e8f8f, 0 0.5px #8e8f8f, 0.5px 0 #8e8f8f, 0 -0.5px #8e8f8f;
}

.intro-text {
  font-size: 1.75rem;
  animation: mobile-fontsize 2s ease-in-out;
}

.resume-btn {
  background-color: #163F4D;
  border: 1px solid #163F4D;
  border-radius: 0.4rem;
  color: #fff;
  margin-top: 2rem;
  padding: 0.75rem 2rem;
}

.resume-btn:hover {
  background-color: #205d71;
  cursor: pointer;
}

@media screen and (min-width: 768px) {

  .intro-section {
    background: none;
    background-image: url('../assets/desktop_intro.webp');
    background-size: cover;
  }

  .intro-text {
    font-size: 2.5rem;
    animation: desktop-fontsize 2s ease-in-out;
  }

}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes desktop-fontsize {
  0% {
    font-size: 0;
  }
  100% {
    font-size: 2.5rem;
  }
}

@keyframes mobile-fontsize {
  0% {
    font-size: 0;
  }
  100% {
    font-size: 1.75rem;
  }
}